import React from 'react';
import './Button.css';
import configData from "../../assets/config.json";
const strftime = require('strftime');

function convertDateToUTC(date) { return new Date(date.getTime() + date.getTimezoneOffset() * 60000); }
function convertTimeToString(timedata) { return strftime("%Y-%m-%dT%H:%M:%S.%LZ", timedata)}

class Submit extends React.Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }
    
    onClick = () => {
        // console.log(this.props.startTime, this.props.stopTime, this.props.patient, this.props.token);
        var start = convertTimeToString(convertDateToUTC(this.props.startTime));
        var stop = convertTimeToString(convertDateToUTC(this.props.stopTime));
        fetch(configData.SERVER_URL + "initiate", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({token: this.props.token, patient: this.props.patient, startTime: start, stopTime: stop})
        }).then(res => res.json()).then(data => {
            this.props.setTimenow(data.download);
            this.props.setDownload(false);
        }).then(() => {
            var timer = setInterval(() => {

                fetch(configData.SERVER_URL + "downloads/?date="+this.props.timenow, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                }).then(res => res.json()).then(data => {
                    if (data.status === "Failed") {
                        this.props.setDownload(null);
                        this.props.setFilename(null);
                    }
                    else if (data.filepath == null) {
                        console.log(data);
                    }
                    else {
                        console.log(data);
                        this.props.setDownload(true);
                        this.props.setFilename(data.filepath);
                        clearInterval(timer);
                    }
                })
                
            }, 5000)
        })
    }
    

    render() {
        if (this.props.token == null || this.props.patient == null) {
            return;
        }
        else if (this.props.download === false && this.props.timenow !== null) {
            return(
                <div style={{display: "flex", padding: "10px",  flexDirection: 'row', justifyContent: 'center'}}>
                    <button className='ButtonDisabled' onClick={this.onClick} disabled={true}>
                        Submit
                    </button>
                </div>
            )
        }
        else {
            return(
                <div style={{display: "flex", padding: "10px",  flexDirection: 'row', justifyContent: 'center'}}>
                    <button className='ButtonEnabled' onClick={this.onClick} >
                        Submit
                    </button>
                </div>
            )
        }
    }
    
}

export default Submit;