import React from 'react';
import './Field.css';

class Email extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }
    
    onChange = (event) => {
        this.props.setEmail(event.target.value);
    }
    
    render() {
        if (this.props.token == null){
            return(
                <div style={{display: "flex", padding: "10px",  flexDirection: 'row', justifyContent: 'center'}}>
                    <input className='InputField' type="text" placeholder="Email Address" onChange={this.onChange}/>
                </div>
            )

        }
        else {
            return;
        }
    }
    
}

export default Email;