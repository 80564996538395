import React from 'react'
import spinner from "../../assets/spinner.gif"
import tick from "../../assets/tick.png"
import exclaim from "../../assets/exclaim.png"


class DownloadStatus extends React.Component {

  render() {
    if (this.props.token == null || this.props.patient == null) {
        return (
          <div style={{display: "flex", padding: "50px",  flexDirection: 'row', justifyContent: 'center'}}>
          <header>
              <h2>     </h2>
          </header>
        </div>
        )
    }
    else if (this.props.timenow == null) {
      return (
        <div style={{display: "flex", padding: "50px",  flexDirection: 'row', justifyContent: 'center'}}>
        <header>
            <h2>     </h2>
        </header>
      </div>
      )
    }

    else if (this.props.download === null) {
      return (
        <div style={{display: "flex", padding: "50px",  flexDirection: 'row', justifyContent: 'center'}}>
          <header>
              <h2>No Data Available</h2>
          </header>
          <img src={exclaim} width={30} height={30} style={{paddingTop: "18px", paddingLeft: "15px"}} alt="GalenData"></img>
        </div>
      )
    }
    else if (this.props.download === false) {

      return (
        <div style={{display: "flex", padding: "50px",  flexDirection: 'row', justifyContent: 'center'}}>
          <header>
              <h2>Downloading</h2>
          </header>
          <img src={spinner} width={50} height={50} style={{paddingTop: "8px", paddingLeft: "10px"}} alt="GalenData"></img>
        </div>
      )

    }
    else {
      return (
        <div style={{display: "flex", padding: "50px",  flexDirection: 'row', justifyContent: 'center'}}>
          <header>
              <h2>Download Complete</h2>
          </header>
          <img src={tick} width={35} height={35} style={{paddingTop: "15px", paddingLeft: "15px"}} alt="GalenData"></img>
        </div>
      )

    }
  }

  
}

export default DownloadStatus