import React from 'react';
import './Button.css';
import configData from "../../assets/config.json";


class Login extends React.Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }
    
    onClick = () => {
        let headers = {'X-TENANT-DOMAIN': configData.TENANT_DOMAIN , 'X-API-VERSION': '3', 'X-APP-TYPE': 'DEVICE'};
        var patientMapper = {};
        var patientList = [];
        let formData  = new FormData();
        // console.log(this.props.emailAddress, this.props.password);
        formData.append("emailAddress", this.props.emailAddress);
        formData.append("password", this.props.password)
        // console.log(this.props.refreshToken);
        if (this.props.refreshToken == null) {
            fetch(configData.GALEN_API + "/auth/login", {
                method: 'POST',
                headers: headers,
                body: formData
                }).then(res =>  {
                // var token = data.headers.authorization;
                // for(const header of res.headers){
                //     console.log(header);
                // }
                var token =  res.headers.get('authorization');
                var refresh = res.headers.get('x-request-token');
                // console.log(token, refresh);
                this.props.setRefreshToken(refresh);
                if (token != null) {
                    this.props.setToken(token);
                }
                if (token == null && refresh != null) {
                    this.props.setMFARequired(true);
                }
                if (token == null && refresh == null) {
                    this.props.setToken(token);
                }
                headers["Authorization"] = token;
                // headers["X-REFRESH-TOKEN"] = refresh;
                this.setState({
                    token: token,
                });
                return token;
            }).then(token => {
                fetch(configData.GALEN_API + "/user/user/?role=Patient", {
                    method: 'GET',
                    headers: headers
                }).then(res2 => res2.json()).then(data2 => {
                    let content = data2.content;
                    for (let i=0; i<content.length; i++) {
                        patientMapper[content[i].firstName + " " + content[i].lastName] = {"userId": content[i].userId, "emailAddress": content[i].emailAddress};
                        patientList.push({value: content[i].userId, label: content[i].fullName})
                    }
                    this.setState({
                    options: patientList,
                    patientDetails: patientMapper
                    })
                    this.props.setPatientList(patientList);
                    this.props.setPatientMap(patientMapper);
                }).catch(error => {
                    console.log("Errored")
                })
            })
        }
        else {
            headers["X-REQUEST-TOKEN"] = this.props.refreshToken;
            fetch(configData.GALEN_API + "/auth/mfa/verify?code="+this.props.mfaValue, {
                method: 'POST',
                headers: headers,
                body: formData
                }).then(res =>  {
                var token =  res.headers.get('authorization');
                console.log(token);
                this.props.setToken(token);
                headers["Authorization"] = token;
                this.setState({
                    token: token,
                });
                return token;
            }).then(token => {
                // headers["X-REFRESH-TOKEN"] = this.props.refreshToken;
                delete headers["X-REQUEST-TOKEN"];
                console.log(headers);
                fetch(configData.GALEN_API + "/user/user/?role=Patient", {
                    method: 'GET',
                    headers: headers
                }).then(res2 => res2.json()).then(data2 => {
                    let content = data2.content;
                    console.log(content);
                    for (let i=0; i<content.length; i++) {
                        patientMapper[content[i].firstName + " " + content[i].lastName] = {"userId": content[i].userId, "emailAddress": content[i].emailAddress};
                        patientList.push({value: content[i].userId, label: content[i].fullName})
                    }
                    this.setState({
                    options: patientList,
                    patientDetails: patientMapper
                    })
                    this.props.setPatientList(patientList);
                    this.props.setPatientMap(patientMapper);
                }).catch(error => {
                    console.log('Errored');
                })
            })
        }
        
    }

    render() {
        if (this.props.token == null) {
            return(
                <div style={{display: "flex", padding: "10px",  flexDirection: 'row', justifyContent: 'center'}}>
                    <button className='ButtonEnabled' onClick={this.onClick}>
                        Login
                    </button>
                </div>
            )

        }
        else {
            return;
        }
    }
    
}

export default Login;