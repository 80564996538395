import React from 'react';
import './Field.css';

class MFA extends React.Component {
    constructor(props) {
        super(props);
        this.state = {mfa: ""};
        this.onChange = this.onChange.bind(this);
    }
    
    onChange = (event) => {
        const re = new RegExp(
            '^[0-9]*$'
        );
        if (event.target.value === '' || re.test(event.target.value)) {
            this.props.setMFA(event.target.value);
            this.setState({mfa: event.target.value});
        }
    }
    
    render() {
        const { mfa } = this.state;
        if (this.props.token == null && this.props.mfaRequired === true){
            return(
                <div style={{display: "flex", padding: "10px",  flexDirection: 'row', justifyContent: 'center'}}>
                    <input className='MFAField' type="text" placeholder="000000" value={mfa} onChange={this.onChange}/>
                </div>
            )

        }
        else {
            return;
        }
    }
    
}

export default MFA;