import React from 'react';

class Password extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }
    
    onChange = (event) => {
        this.props.setPassword(event.target.value);
    }
    

    render() {
        if (this.props.token == null) {

            return(
                <div style={{display: "flex", padding: "10px",  flexDirection: 'row', justifyContent: 'center'}}>
                    <input className='InputField' type="password" placeholder="Password" onChange={this.onChange} />
                </div>
            )

        }
        else {
            return;
        }
    }
    
}

export default Password;