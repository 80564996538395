import React from 'react';
import DateTimePicker from 'react-datetime-picker';

const duration = 7;

class StopDateTime extends React.Component {
    constructor(props) {
        super(props);
        this.state = {stopTime: new Date()};
        this.props.setStopTime(this.state.stopTime);
        this.onChange = this.onChange.bind(this);
    }

    onChange = (timeData) => {
        this.props.setStopTime(timeData);
        this.setState({stopTime: timeData});
    }

    render() {
        if (this.props.token == null) {
            return;
        }
        if (this.props.maxStop == null) {
            let minDate = new Date();
            minDate = minDate.setDate(minDate.getDate() - duration);
            return (
                <div style={{padding: "0px 75px"}}>
                    <h3>Pick Stop Time</h3>
                    <div style={{fontSize: "20px", padding: "20px 0px"}}>
                        <DateTimePicker onChange={this.onChange} value={this.props.stopTime} format={"y/M/d H:mm"} minDate={new Date(minDate)} maxDate={new Date()} />
                    </div>
                </div>
            )
        }
        else {
            let minDate = new Date(this.props.maxStop);
            minDate = minDate.setDate(minDate.getDate() - duration);

            return (
                <div style={{padding: "0px 75px"}}>
                    <h3>Pick Stop Time</h3>
                    <div style={{fontSize: "20px", padding: "20px 0px"}}>
                        <DateTimePicker onChange={this.onChange} value={new Date(this.props.stopTime.getTime())} format={"y/M/d H:mm"} minDate={new Date(minDate)} maxDate={new Date(this.props.maxStop.getTime()+1)} />
                    </div>
                </div>
            )
        }
        
    }
}
  
export default StopDateTime