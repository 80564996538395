import './App.css';
import Header from './components/Header/Header';
import StartDateTime from './components/DateTime/StartDateTime';
import StopDateTime from './components/DateTime/StopDateTime';
import PatientList from './components/Dropdown/PatientList';
import Submit from './components/Button/Submit';
import Login from "./components/Button/Login"
import Email from './components/Fields/Email';
import Password from './components/Fields/Password';
import WrongCredentials from './components/Header/WrongCredentials';
import Download from './components/Button/Download';
import DownloadStatus from './components/Header/DownloadStatus';
import MFA from './components/Fields/MFA'

import React from 'react';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first:true, //First Time Trying to Login
      email: null, //Email Address for logging in
      password: null, //Password for logging in
      download: false, //Whether download is complete on server
      timenow: null, //To check whether download is completed - folder name where downloads are stored
      filename: null, //Filename of file to be downloaded
      token: null, //Bearer Token
      mfaRequired: false, //Is MFA Enabled
      mfaValue: null, // What is the MFA Token
      refreshToken: null,
      selectedPatient: null, //Patient Selected from Dropdown
      patientList: [], //List of all patients
      patientMap: null, //PatientName to Details map
      startTime: null, //Start Time from Time Picker
      stopTime: null, //Stop Time from Time Picker
      startTimeAsDate: null, // Start Time as Date()
      stopTimeAsDate: null, // Stop Time as Date()
      maxStopAsDate: null //Max Stop Time 
    }
    this.setToken = this.setToken.bind(this);
    this.setPatient = this.setPatient.bind(this);
    this.setPatientMap = this.setPatientMap.bind(this);
    this.setStartTime = this.setStartTime.bind(this);
    this.setStopTime = this.setStopTime.bind(this);
    this.setPatientList = this.setPatientList.bind(this);

    this.setEmail = this.setEmail.bind(this);
    this.setPassword = this.setPassword.bind(this);

    this.setDownload = this.setDownload.bind(this);
    this.setTimenow = this.setTimenow.bind(this);
    this.setFilename = this.setFilename.bind(this);
    this.setMaxStopTime = this.setMaxStopTime.bind(this);

    this.setRefreshToken = this.setRefreshToken.bind(this);

    this.setMFAToken = this.setMFAToken.bind(this);
    this.setMFARequired = this.setMFARequired.bind(this);
  }

  setRefreshToken = (refreshToken) => {
    this.setState({
      refreshToken: refreshToken
    })
  }

  setMFAToken = (mfaToken) => {
    this.setState({
      mfaValue: mfaToken
    });
  }

  setMFARequired = (boolVal) => {
    this.setState({
      mfaRequired: boolVal
    });
  }

  setDownload = (download) => {
    this.setState({
      download: download
    });
  }

  setTimenow = (timenow) => {
    this.setState({
      timenow: timenow
    });
  }

  setFilename = (filename) => {
    this.setState({
      filename: filename
    });
  }

  setToken = (token) => {
    this.setState({
      first: false,
      token: token,
      showLogin: false,
      showDashboard: true
    });
  }

  setPatient = (selectedOption) => {
    this.setState({
      selectedPatient: selectedOption
    })
    console.log(this.state)
  }

  setPatientMap = (patientDetails) => {
    this.setState({
      patientMap: patientDetails
    })
  }

  setPatientList = (patientList) => {
    this.setState({
      patientList: patientList
    })
  }

  setStartTime = (timedata) => {
    this.setState({
      startTimeAsDate: timedata
    })
  }

  setStopTime = (timedata) => {
    this.setState({
      stopTimeAsDate: timedata
    })
  }

  setMaxStopTime = (timedata) => {
    this.setState({
      maxStopAsDate: timedata
    })
  }

  setEmail = (email) => {
    this.setState({
      email: email
    })
  }

  setPassword = (password) => {
    this.setState({
      password: password
    })
  }

  render() {
    return (
      <div className="container">
        <Header />
        <div className="loginSegment">
          <div className= "inputFields" style={{display: "flex", padding: "20px",  flexDirection: 'row', justifyContent: 'center'}}>
            <Email setEmail={this.setEmail} token={this.state.token} />
            <Password setPassword={this.setPassword} token={this.state.token} />
            <MFA setMFA={this.setMFAToken} token={this.state.token} mfaRequired={this.state.mfaRequired} />
          </div>
          <Login emailAddress={this.state.email} password={this.state.password} refreshToken={this.state.refreshToken} mfaValue={this.state.mfaValue} setToken = {this.setToken} setPatientMap = {this.setPatientMap} setPatientList = {this.setPatientList} token={this.state.token} setMFAToken = {this.setMFAToken} setMFARequired = {this.setMFARequired} setRefreshToken = {this.setRefreshToken}/>
        </div>
        <div className="dashboard">
          <PatientList setPatient = {this.setPatient} options = {this.state.patientList} token = {this.state.token} setDownload={this.setDownload} setTimenow={this.setTimenow} setFilename={this.setFilename} />
          <div className= "rowTime" style={{display: "flex", padding: "50px",  flexDirection: 'row', justifyContent: 'center'}}>
            <StartDateTime setStartTime = {this.setStartTime} setStopTime = {this.setStopTime} setMaxStopTime = {this.setMaxStopTime} token = {this.state.token} startTime = {this.state.startTimeAsDate}/>
            <StopDateTime setStopTime = {this.setStopTime} token = {this.state.token} stopTime = {this.state.stopTimeAsDate} maxStop = {this.state.maxStopAsDate}/>
          </div>
          <Submit startTime={this.state.startTimeAsDate} stopTime={this.state.stopTimeAsDate} patient={this.state.selectedPatient} token={this.state.token} download={this.state.download} timenow={this.state.timenow} setDownload={this.setDownload} setTimenow={this.setTimenow} setFilename={this.setFilename}/>
          <DownloadStatus token={this.state.token} patient={this.state.selectedPatient} download={this.state.download} timenow={this.state.timenow}/>
          <Download download={this.state.download} token={this.state.token} patient={this.state.selectedPatient} filename={this.state.filename}/>
          <WrongCredentials token={this.state.token} first={this.state.first} mfaRequired={this.state.mfaRequired}/>

        </div>
      </div>
    )
  }
}

export default App;
