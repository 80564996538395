import React from 'react'

class WrongCredentials extends React.Component {

    render() {

        if (this.props.token == null && this.props.first === false && this.props.mfaRequired === false) {
            return (
                <header style={{display: "flex", flexDirection: 'row', justifyContent: 'center'}}>
                    <h2>Incorrect Credentials. Please Input Again.</h2>
                </header>
              )
        }
        else {
            return;
        }

    }
}

export default WrongCredentials