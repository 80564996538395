import React from 'react';
import DateTimePicker from 'react-datetime-picker';

const duration = 7;

class StartDateTime extends React.Component {
    constructor(props) {
        super(props);
        var temp = new Date();
        temp = temp.setDate(temp.getDate() - duration);
        this.state = {startTime: new Date(temp), maxStart: new Date()};
        this.props.setStartTime(this.state.startTime);
        this.onChange = this.onChange.bind(this);
    }

    onChange = (timeData) => {
        this.props.setStartTime(timeData);
        this.setState({startTime: timeData});
        var today = new Date();
        var maxStop = new Date(timeData);
        maxStop = maxStop.setDate(maxStop.getDate() + duration);
        maxStop = new Date(maxStop);
        if (maxStop.getTime() > today.getTime()) {
            this.props.setStopTime(today);
            this.props.setMaxStopTime(today);
        }
        else {
            this.props.setStopTime(maxStop);
            this.props.setMaxStopTime(maxStop);
        }
    }

    render() {
        if (this.props.token == null) {
            return;
        }
        else {
            return (
                <div style={{padding: "0px 75px"}}>
                    <h3>Pick Start Time</h3>
                    <div style={{fontSize: "20px", padding: "20px 0px"}}>
                        <DateTimePicker onChange={this.onChange} value={this.state.startTime} format={"y/M/d H:mm"} maxDate={this.state.maxStart}/>
                    </div> 
                </div>
            )
        }
    }
}
  
export default StartDateTime