import React from 'react';
import Select from 'react-select';


class PatientList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  
  handleChange = (selectedOption) => {
    this.setState({
      selectedOption: selectedOption,
    })
    this.props.setPatient(selectedOption.value);
    this.props.setDownload(false);
    this.props.setTimenow(null);
    this.props.setFilename(null);
  }

  render() {
    const { selectedOption } = this.state;
    if (this.props.token == null) {
      return;
    }
    else {
      return (
        <div style={{margin: "15px"}}>
          <Select value={selectedOption} onChange={this.handleChange} options={this.props.options} placeholder={"Select Patient"}/>
        </div>
        
      )
    }
  }
}

export default PatientList