import React from 'react';
import './Button.css';
import configData from "../../assets/config.json";

class Download extends React.Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }
    
    onClick = async () => {
        window.open(configData.SERVER_URL + this.props.filename);
    }
    

    render() {
        if (this.props.token == null || this.props.patient == null) {
            return;
        }
        else if (this.props.filename == null) {
            return(
                <div style={{display: "flex", padding: "10px", marginTop: "100px",  flexDirection: 'row', justifyContent: 'center'}}>
                    <button className='ButtonDisabled' onClick={this.onClick} disabled={true}>
                        Download
                    </button>
                </div>
            )
        }
        else {
            return(
                <div style={{display: "flex", padding: "10px", marginTop: "100px",  flexDirection: 'row', justifyContent: 'center'}}>
                    <button className='ButtonEnabled' onClick={this.onClick}>
                        Download
                    </button>
                </div>
            )
        }
    }
    
}

export default Download;